body {
  font-family: "GothamBook" !important;
}
.rootBackground {
  background-image: url("./assets/image/9267\ 1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100svh;
  width: 100vw;
}

* {
  box-sizing: border-box;
  /* margin: 0;
  padding: 0;
  transition: all ease-in-out 500ms;
  -webkit-transition: all ease-in-out 500ms;
  -moz-transition: all ease-in-out 500ms;
  -ms-transition: all ease-in-out 500ms;
  -o-transition: all ease-in-out 500ms; */
}

input::placeholder {
  font-family: 'Arial';
}

.form-control {
  border-color: #007dc6 !important;
}
.form-select {
  border-color: #007dc6 !important;
}
.rootDateselect {
  width: 18rem;
  border: 1px solid #007dc6;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.644);
  border-radius: 10px;
  font-size: 1rem;
  background: url("./assets/mainIcon/icons/calendar\ 2.png") 260px 1.5rem
    no-repeat #fff;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: black;
  opacity: 1;
  display: block;
  overflow: hidden;
  background: url("./assets/mainIcon/icons/calendar\ 2.png") 260px 1.5rem
    no-repeat #fff;
  background-position: 100% 50%;
  float: right;
  border-width: thin;
}

input[type="date"]:focus {
  box-shadow: 2px 2px 5px #007dc6 !important;
}
input[type="number"]:focus {
  box-shadow: 2px 2px 5px #007dc6 !important;
}

input[type="text"]:focus {
  box-shadow: 2px 2px 5px #007dc6 !important;
}
input[type="email"]:focus {
  box-shadow: 2px 2px 5px #007dc6 !important;
}
input[type="password"]:focus {
  box-shadow: 2px 2px 5px #007dc6 !important;
}
select[name="rootField"]:focus {
  box-shadow: 2px 2px 5px #007dc6 !important;
}
select[name="UHID"]:focus {
  box-shadow: 2px 2px 5px #007dc6 !important;
}
select[id="rootField"]:focus {
  box-shadow: 2px 2px 5px #007dc6 !important;
}
textarea:focus {
  box-shadow: 2px 2px 5px #007dc6 !important;
}
.rootSelect {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.507);
  border-radius: 10px;
  font-size: 1rem;
  background: url("./assets/mainIcon/icons/Vector.png") no-repeat #fff;
  background-position: 95% 50%;
  border: 1px solid #007dc6;
}
select[name="UHID"] {
  /* width : 18rem; */
  overflow: hidden;
  color: rgba(0, 0, 0, 0.507);
  border-radius: 10px;
  font-size: 1rem;
  background: url("./assets/mainIcon/icons/Vector.png") no-repeat #fff;
  background-position: 95% 50%;
  border: 1px solid #007dc6;
}
select[id="rootField"] {
  /* width : 18rem; */
  overflow: hidden;
  color: rgba(0, 0, 0, 0.507);
  border-radius: 6px;
  font-size: 1rem;
  background: url("./assets/mainIcon/icons/Vector.png") no-repeat #fff;
  background-position: 95% 50%;
  border: 1px solid #007dc6;
}

.emojicss .text-center {
  width: 100%;
  text-align: center !important;
}
select#rootField {
  margin-bottom: 10px;
}

.rootSelect {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.507);
  border-radius: 10px;
  font-size: 1rem;
  background: url("./assets/mainIcon/icons/Vector.png") no-repeat #fff;
  background-position: 95% 50%;
  border: 1px solid #007dc6;
}
.universsalFocus:focus {
  box-shadow: 2px 2px 5px #007dc6 !important;
}

.navbartopbook .universsalFocus:focus {
  box-shadow: 2px 2px 5px #007dc6 !important;
  box-shadow: none !important;
}
.mainGrid {
  display: grid;
  gap: 20px;
  grid-template-columns: 18rem auto;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
}

@font-face {
  font-family: "Gotham-Black"; /*Can be any text*/
  src: local("Gotham-Black"), url("./fonts/Gotham-Black.otf") format("opentype");
}
@font-face {
  font-family: "GothamBook"; /*Can be any text*/
  src: local("GothamBook"), url("./fonts/GothamBook.ttf") format("truetype");
}
#transitin {
  animation: transitin infinite 1s linear;
  /* transform: rotate(360); */
  font-size: 40px;
  color: #0d6efd;
}
@keyframes transitin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

svg.iconify.iconify--mdi {
  margin-right: -2px;
  font-size: 20px;
}
.outer_poptext {
  right: 1rem !important;
  left: 1rem !important;
  height: min-content;
}

.newtext {
  min-height: 230px !important;
  top: 30% !important;
}
.MuiAccordionDetails-root.css-15v22id-MuiAccordionDetails-root .likedislike p {
  text-align: left;
}
.emojicss {
  display: flex;
  margin-bottom: 10px;
}
.emojicss .col-3 p {
  margin-bottom: 3px;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 20px 0 10px !important;
}
.subtitlename {
  margin: 0 0 10px 0;
  color: #000;
  font-family: "GothamBook" !important;
}
.feedback-accordion-header {
  font-weight: 550 !important;
  font-family: "GothamBook" !important;
  font-weight: bold !important;
}
.MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content {
  margin-bottom: 0 !important;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 27px !important;
}

.profilecanclebtn .pt-3 {
  padding-top: 1rem !important;
  float: left;
  margin: 0 10px 0;
}

select.borderradius {
  /* width: 18rem; */
  overflow: hidden;
  color: rgba(0, 0, 0, 0.507);
  /* border-radius: 10px; */
  font-size: 1rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAKCAYAAABSfLWiAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACgSURBVHgBjdJtDYQwDAbglvvPIQEJZ+Gc3BngQwESwAESwAFIwAFIQAGlGwsh2VpoQoDu3ZNmGQAXZXFF+XumIvnAg+JcavN5XJt/5MYPiFq3vgLiF5t10gDOD/yZ2sZGZcSvkZ/FZRITkCbyALPvFfUoLHoTBYEjs6ASOiENsGdyM66B/tyrJcBDBOhaHhBEFCgIiEgAEoHbcpeqs6BSO7FjcJa5GsotAAAAAElFTkSuQmCC)
    no-repeat #fff;
  background-position: 95% 50%;
  border: 1px solid #007dc6;
  min-height: 47px;
  border-radius: 30px;
}
select.borderradius:focus {
  box-shadow: none !important;
}
.navbartopbook select#rootField {
  width: 100%;
  margin-bottom: 10px;
  height: 50px;
  padding: 0px 14px !important;
}
.headersdropdown select#rootField {
  margin-bottom: 0px !important;
}
.modalalingment {
  margin: 10% 23%;
}
.contact-us-modal,
.change-password-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}
.contact-us-modal .contact-us {
  width: 600px;
  height: 275px;
  overflow-y: auto;
  background-color: #fff !important;
}
.change-password-modal .change-password {
  padding-top: 50px;
  width: 600px;
  height: 394px;
  overflow-y: auto;
  background-color: #fff !important;
}
.modalAlingmentNotification {
  position: absolute;
  top: 14.5vh;
  right: 10%;
  position: fixed;
}
.cross-icon {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  top: 5%;
  right: 5%;
  position: fixed;
}
/* .modalAlingmentNotification::before{
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid rgb(255, 255, 255);
  right: 35%;
  top:-0.5%;
  transform: rotate(45deg);
  z-index: -1;
} */
.loaderdiv {
  float: left;
  width: 100%;
  padding: 20px;
  min-height: 200px;
  text-align: center;
  margin: 10% auto;
}
.errorText {
  color: red;
}
.report-date input {
  height: 50px !important;
  padding: 0px 14px !important;
}
.myappointment .form-control {
  margin-bottom: 0px !important;
}
.form-control {
  height: 50px;
  margin-bottom: 10px;
}
.modalalingment .container-fluid.p-5.mt-2.payment-summary {
  width: 600px;
  height: 275px;
  overflow-y: auto;
  background-color: #fff !important;
}
.modalAlingmentNotification .container-fluid.p-2.mt-2.payment-summary {
  width: 507px;
  height: 394px;
  overflow-y: auto;
  background-color: #fff !important;
}
*::-webkit-scrollbar {
  display: none;
}
.preventive-modal::-webkit-scrollbar {
  -webkit-appearance: none !important;
  width: 5px !important;
  z-index: 9999 !important;
  display: block !important;
}
.preventive-modal::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: rgba(4, 103, 161, 0.772) !important;
  -webkit-box-shadow: 0 0 1px rgba(79, 101, 106, 0.5) !important;
}
.Date input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: white;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 0px 14px;
  padding-right: 0;
  height: 50px;
}
.Date > .Mui-error > fieldset {
  border-color: #007dc6 !important;
}
.centerLoader {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 78vw;
  height: 143vh;
  background-color: transparent;
}
.centerLoader1 {
  position: absolute;
  top: 50%;
  right: 37%;
  /* transform: translate(-50%, -50%); */
  position: fixed;
}
.centerLoaderLog {
  position: absolute;
  top: 50%;
  right: 50%;
  position: fixed;
}
fieldset.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: #007dc6;
}

.usericon {
  border-left: 1px solid #ff6f0f;
  width: auto;
  height: 50px;
}
.doctordetaildiv {
  min-height: 365px;
}
ul.preventive-modal {
  max-height: 350px;
  overflow-y: scroll;
}
/* .newtext button{
  margin-left: 30% !important;
} */

.mainenavbar {
  width: 100%;
  padding: 15px 10px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainnavbar-left {
  width: 34%;
}
.mainnavbar-center {
  width: 34%;
}
.mainnavbar-right {
  width: 26%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profiledetails {
  margin-left: 10px;
}
.profiledetails h4 {
  margin-bottom: 0px;
  font-size: 16px;
  text-transform: uppercase;
}
.profiledetails p {
  margin-bottom: 0px;
  font-size: 14px;
}
.mainselectdropdown {
  margin-bottom: 0px !important;
}

.mainheaderdoctor {
  font-size: 20px;
  margin-top: 20px;
}
.timeline-main {
  width: 800px;
  margin: 0 auto;
}
.timeline-main ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.timeline-main ul li {
  list-style-type: none;
  width: 18%;
    position: relative;
    text-align: center;
    
}
.timeline-main ul li p {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  background-color: #777;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: 0 auto;
}
.timeline-main ul li::after {
  content: "";
    position: absolute;
    top: 32px;
    width: 156px;
    height: 10px;
    background-color: #777;
    z-index: 0;
    left: 126px;
    right: 0;
}
.timeline-main ul li:last-child::after {
  display: none;
}
.timeline-main ul li span {
  text-align: center;
  display: block;
  min-height: 40px;
}
.active-tracker {
  background-color: green !important;
}

.mainGrid .navbartopbook .NewNavClass {
  z-index: 100;
  position: relative;
}
@media (max-width: 540px) {
  .timeline-main {
    width: 100%;
    margin: 0 auto;
  }
  .timeline-main ul {
    margin: 0px;
    padding: 0px;
    display: inline-block;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  .timeline-main ul li {
    list-style-type: none;
    position: relative;
    display: flex;
    align-items: center;
  }
  .timeline-main ul li::after {
    content: "";
    position: absolute;
    top: 55px;
    width: 10px;
    height: 100px;
    background-color: #777;
    z-index: -1;
    left: 30px;
  }
  .timeline-main ul li span {
    text-align: center;
    margin-left: 20px;
    width: 50%;
  }
}
@media screen and (min-width: 567px) and (max-width: 768px) {
  .mainGrid .navbartopbook .NewNavClass {
    align-items: flex-start;
  }
  .mainGrid .navbartopbook .NewNavClass .mainenavbar{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0.7rem;
  }

  .mainGrid .navbartopbook .NewNavClass .mainenavbar .mainnavbar-left {
    grid-column: 1;
    grid-row: 2;
    width: 100%;
  }

  .mainGrid .navbartopbook .NewNavClass .mainenavbar .mainnavbar-center {
    grid-column: 2;
    grid-row: 2;
    width: 100%;
  }

  .mainGrid .navbartopbook .NewNavClass .mainenavbar .mainnavbar-right {
    grid-column: 2;
    grid-row: 1;
  }
  
}
@media (min-width: 768px) and (max-width: 820px) {
  .navbartopbook .px-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .modalalingment .container-fluid.p-5.mt-2.payment-summary {
    width: 100%;
  }
  .modalAlingmentNotification {
    right: 10%;
    left: 28%;
  }
}
@media (min-width: 820px) and (max-width: 1024px) {
  .mainGrid .navbartopbook .NewNavClass .mainenavbar {
    justify-content: space-around;
  }
  .mainGrid .navbartopbook .NewNavClass .mainenavbar .mainnavbar-right .profiledetails{
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50% !important;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25% !important;
  }
  #apnmnt_btn,
  #profile_btn {
    text-align: start;
    font-size: 0.7rem !important;
  }
  .centerLoader1 {
    position: absolute;
    top: 28%;
    right: 26%;
    /* transform: translate(-50%, -50%); */
    position: fixed;
  }
  .centerLoaderLog {
    position: absolute;
    top: 50%;
    right: 40%;
    /* transform: translate(-50%, -50%); */
    position: fixed;
  }
}
@media (max-width: 450px) {
  .container-fluid {
    padding: auto 0px !important;
  }
  .mainGrid {
    display: inherit;
  }
  .loader-container .p-5 {
    padding: 1rem !important;
  }
  .navbartopbook select#rootField {
    margin: 0;
    width: 100%;
    margin-bottom: 20px;
  }
  .show-sidebar {
    padding-top: 0px;
    display: inline-flex !important;
    padding-left: 10px;
    width: 60%;
  }
  .profilenamediv {
    display: none;
  }
  /* .navbartopbook img{width: 20px !important;} */
  .iconify--ion {
    width: 25px !important;
  }
  .headersdropdown select.borderradius {
    min-height: 40px;
  }
  .navbartopbook select#rootField {
    height: 50px;
    margin: 0;
  }
  .navbartopbook select.rootselect {
    height: 50px !important;
    margin-bottom: 10px !important;
  }
  .searchbydoctor .form-control {
    height: 40px;
  }
  .searchbydoctor .btn {
    padding: 7px 0.9em !important;
    border: none !important;
  }
  .ambulance-icon {
    width: 30px !important;
  }
  .usericon {
    height: 30px;
  }
  .doctordetaildiv {
    min-height: auto;
  }
  .ipadview {
    margin-bottom: 20px;
    align-items: center;
  }
  .weekday {
    justify-content: flex-start !important;
  }
  .calltime {
    margin-bottom: 20px;
  }
  .mainenavbar {
    justify-content: flex-end;
  }
  .mainnavbar-right {
    width: 36%;
  }
  .show-sidebar {
    position: absolute;
    top: 4%;
  }
  .profiledetails {
    display: none;
  }
  .Date input {
    height: 40px !important;
  }
}
@media (max-width: 391px) {
  .show-sidebar {
    width: 57%;
  }
}
@media (max-width: 376px) {
  .show-sidebar {
    width: 55%;
  }
  .mainnavbar-right {
    width: 40%;
  }
  .cross-button {
    top: 1%;
    right: 25%;
  }
}
@media (max-width: 361px) {
  .show-sidebar {
    width: 53%;
  }
  .mainnavbar-right {
    width: 42%;
  }
  .cross-button {
    top: 1%;
    right: 22%;
  }
}
@media (max-width: 321px) {
  .show-sidebar {
    width: 47%;
    top: 5%;
  }
  .mainnavbar-right {
    width: 48%;
  }
  .cross-button {
    top: 1%;
    right: 12%;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .buttonmobset button {
    padding: 8px 40px !important;
  }
  .signInBox {
    width: 98vw;
  }
  .modalAlingmentNotification {
    right: 2%;
    left: 2%;
  }
  .modalAlingmentNotification .container-fluid.p-2.mt-2.payment-summary {
    width: 100%;
  }
  .change-password-modal {
    top: 20%;
    left: 2%;
    right: 2%;
    transform: translate(0, 0);
  }
  .change-password-modal .change-password {
    width: 100%;
  }

  .querySuccessMessage {
    left: 1rem !important;
    right: 1rem !important;
    bottom: unset !important;
    top: 50% !important;
    transform: translate(0, -50%);
  }
}
