#prevent_check {
  position: relative;
  z-index: 1;
  min-height: 270px;
  height: 100%;
  border: 1px solid #007dc6;
  box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

#prevent_check::before {
  content: "";
  width: 100%;
  height: 80px;
  background-color: #e4f1f9;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  position: absolute;
  z-index: 999;
}
.whole-body {
  font-weight: 900;
  color: #007dc6;
}
#modal_rupe {
  color: #ff6f0f;
  font-size: 20px;
  font-weight: 700;
}
