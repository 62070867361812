.signUpBox {
    height: auto;
    width: 45vw;
    display: flex;
    padding-bottom: 0px;
    border-radius: 10px;
    /* justify-content: center; */
    /* align-items: center; */
    /* text-align: center; */
    flex-direction: column;
    position: relative;
}

.signUpBox .backToLogin {
    position: absolute;
    top: 1rem;
    left: 1rem;
}



.signUpBox .ps-5.pe-5.pb-5 input.iphoneFix {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    max-height: 50px;
    margin-bottom: 10px;
    border-color: #007dc6 !important;
}

.signUpBox .ps-5.pe-5.pb-5 input.iphoneFix:focus {
    box-shadow: 2px 2px 5px #007dc6 !important;
}

.signUpBox .ps-5.pe-5.pb-5 input.iphoneFix:focus-visible {
    outline: none;
}

.signUpBox .ps-5.pe-5.pb-5 input.iphoneFix::placeholder {
    color: #818181 !important;
}

.signUpBox .howToCreateAPassword ul {
    list-style: square;
    padding-left: 0.7rem;
}

.signUpBox .howToCreateAPassword ul li::marker {
    content: '- ';
}

.signUpBox .howToCreateAPassword ul li {
    font-family: 'Gothambook' !important;
    font-size: 14px;
}

.signUpBox .backToLogin svg path {
    transition: all 0.3s ease;
}

.signUpBox .backToLogin:hover svg path {
    fill: #007DC6;
}

.signUpBox span {
    color: orange;
    text-decoration: underline;
    cursor: pointer;
}

.signUpBox .spanios {
    color: orange;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 0;
}

.mainBox {
    position: absolute;
    left: 27.25%;
    right: 27.19%;
    top: 10.93%;
    bottom: 14.67%;
    background: #FFFFFF;
    opacity: 0.9;
    border: 1px solid #007DC6;
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.SetPasswordBox {
    
}

@media (min-width: 768px) and (max-width: 920px) {
    .signUpBox {
        width: 65vw;
    }

    img.iconwidth {
        position: absolute;
        top: 105px !important;
        left: 10px;
        width: 220px !important;
    }
}

@media (min-width: 280px) and (max-width: 767px) {
    .signUpBox {
        width: 98vw;
    }

    .signUpBox .ps-5.pe-5.pb-5 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        display: flex;
        flex-direction: column;
    }

    img.iconwidth {
        position: absolute;
        top: 70px !important;
        left: 10px;
        width: 120px !important;
    }

    .mainBox {
        position: relative;
        margin: 0px 12px;
        margin-top: 35%;
    }

    .SetPasswordBox {
        /* position: absolute; */
        top: 50%;
        left: 50%;
        right: unset;
        transform: translate(-50%, -50%);
        margin: 0;
        width: 95%;
    }
}