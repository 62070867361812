.signInBox {
    height: auto;
    width: 45vw;
    display: flex;
    padding-bottom: 40px;
    border-radius: 10px;
    /* justify-content: center; */
    /* align-items: center; */
    /* text-align: center; */
    flex-direction: column;
}

.signInBox .ps-5.pe-5.pb-5 input::placeholder {
    color: #818181;
}

.signInBox span {
    color: orange;
    text-decoration: underline;
    cursor: pointer;
}

@media (min-width: 768px) and (max-width: 920px) {
    .signInBox {
        width: 65vw;
    }

    img.iconwidth {
        position: absolute;
        top: 70px !important;
        left: 10px;
        width: 220px !important;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .signInBox {
        width: 98vw;
    }

    .signInBox .ps-5.pe-5.pb-5 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        display: flex;
        flex-direction: column;
    }

    img.iconwidth {
        position: absolute;
        top: 70px !important;
        left: 10px;
        width: 120px !important;
    }
}