.loader-container {
  margin: 0 auto;
  padding: 0;
  position: relative;
}
.mainnavbar-right {
  width: unset !important;
}
.cell-center {
  text-align: center;
  margin: auto;
}
.table-filter {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 20px 20px 0 10px;
  gap: 20px;
}
.search-table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.table-page-select {
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #007dc6;
  height: 50px;
}
.table-status-container {
  padding: 6px 15px;
  border-radius: 5px;
  text-align: center;
  width: 120px;
  font-size: 12px;
}
.table td{font-size: 12px;}
.table-service {
  text-align: left;
  width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.table-download {
  padding: 6px 29px;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007dc6;
  color: white;
}
.report-header {
  text-align: center;
  margin-top: 10px;
  font-family: "Gotham Black" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 33px;
  color: #007dc6;
}
.SearchForMobile {
  width: 100%;
  max-width: 500px;
}
@media (min-width: 768px) and (max-width: 820px) {
  .sidebar-view {
    margin: 0;
    padding: 0;
    display: none;
  }
  .mainGrid {
    display: grid;
    grid-template-columns: 1fr;
  }
  .navbartopbook {
    width: 100%;
  }
  .navbartopbook div:nth-child(2) {
    flex-wrap: nowrap !important;
  }
  .navbartopbook .row.feedbackList {
    flex-wrap: wrap !important;
  }
  .loader-container .patientRegistrationForm .birthColumn {
    padding: 0 !important;
  }
  .show-sidebar {
    position: absolute;
    top: 6%;
    left: 5%;
  }
  .mainenavbar {
    justify-content: space-between !important;
    padding: 15px 10px 15px 70px
  }
  .mainenavbar .mainnavbar-right .profiledetails {
    display: none;
  }
  .mainnavbar-right {
    width: unset !important;
  }

  .navbartopbook .all-doctors-list {
    flex-wrap: nowrap !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .navbartopbook .all-doctors-list div {
    width: 100% !important;
  }

  .navbartopbook .all-doctors-list div .card .card-title img{
    object-fit: cover;
    width: unset !important;
  }
}
@media (min-width: 280px) and (max-width: 767px) {
  .sidebar-view {
    margin: 0;
    padding: 0;
    display: none;
  }
  .mainGrid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .loader-container div:nth-child(2) {
    margin-top: 0 !important;
  }
  .loader-container .patientRegisterBtn {
    margin-top: 1rem;
  }
  .show-sidebar {
    margin-left: 15px;
    margin-top: 4%;
  }
  .mainenavbar {
    justify-content: flex-end !important;
  }
  .mainnavbar-right {
    width: unset !important;
  }
  .mainGrid .navbartopbook .NewNavClass {
    flex-wrap: wrap !important;
  }
}
